import React from 'react';

import NavLinks from './Links';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';

import sociana_logo_jpg from '../../Assets/Navbar/sociana_logo.png';
import sociana_logo_webp from '../../Assets/Navbar/sociana_logo.webp';

import styles from './navbar.module.css';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onMobileNavVisible: false
    };
  }

  showMenu = () => {
    this.setState({
      onMobileNavVisible: !this.state.onMobileNavVisible
    });
  };

  render() {
    const { onMobileNavVisible } = this.state;
    return (
      <div className={`${styles.container} center`}>
        <a href="/">
          {/* <img className={styles.nav_logo} src={sociana_logo} alt="logo" /> */}
          <Image
            className={styles.nav_logo}
            webpImage={sociana_logo_webp}
            src={sociana_logo_jpg}
            alt="logo"
          />
        </a>

        <div className={styles.navigation}>
          <span
            className={`${styles.menu_button} ${
              onMobileNavVisible && styles.menu_button_active
            }`}>
            <Icon
              icon={onMobileNavVisible ? 'close' : 'bars'}
              size="lg"
              onClick={this.showMenu}
            />
          </span>
          <div
            className={`${styles.mobile_links} ${
              onMobileNavVisible === true ? styles.mobile_links_active : null
            }`}>
            <NavLinks onClick={this.showMenu} />
          </div>

          <div className={`${styles.desktop_links}`}>
            <NavLinks />
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
