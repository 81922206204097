import React from 'react';

const Icon = (props) => {
  const { icon, size, dataId = null, onClick = null, ...restProps } = props;
  return (
    <i
      className={`fa fa-${icon} fa-${size}`}
      data-id={dataId}
      onClick={onClick}
      {...restProps}
    />
  );
};

export default Icon;
