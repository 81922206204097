import React from 'react';

import Icon from '../Icon/Icon';

import {
  NAV_TEXT_1,
  NAV_TEXT_2,
  NAV_TEXT_3,
  NAV_TEXT_4,
  NAV_TEXT_5,
  NAV_TEXT_6
} from '../../Constants';

const NavLinks = (props) => (
  <>
    <a href="#features" onClick={props.onClick}>
      {NAV_TEXT_1}
    </a>

    <a href="#reviews" onClick={props.onClick}>
      {NAV_TEXT_2}
    </a>

    <a href="#about" onClick={props.onClick}>
      {NAV_TEXT_3}
    </a>

    <a href="#screenshot" onClick={props.onClick}>
      {NAV_TEXT_4}
    </a>

    <a href="#demo" onClick={props.onClick}>
      {NAV_TEXT_5}
    </a>

    <a href="#contact" onClick={props.onClick}>
      {NAV_TEXT_6}
    </a>

    <a
      href="https://www.facebook.com/SocianaApp/"
      target="_blank"
      rel="noreferrer noopener">
      <Icon icon="facebook" size="lg" />
    </a>
  </>
);

export default NavLinks;
