import React from 'react';
import loadable from '@loadable/component';
import Fallback from '../../Routes/RouteFallback';
import Navbar from '../../Components/Navbar/Navbar';
import MetaComponent from '../../Components/MetaComponent';

const Landing = loadable(
  () => import(/* webpackChunkName: "Landing" */ './Landing/Landing'),
  {
    fallback: <Fallback />
  }
);
Landing.displayName = 'Landing';

const Features = loadable(
  () => import(/* webpackChunkName: "Features" */ './Features/Features'),
  {
    fallback: <Fallback />
  }
);
Features.displayName = 'Features';

const Reviews = loadable(
  () => import(/* webpackChunkName: "Reviews" */ './Reviews/Reviews'),
  {
    fallback: <Fallback />
  }
);
Reviews.displayName = 'Reviews';

const About = loadable(
  () => import(/* webpackChunkName: "About" */ './About/About'),
  {
    fallback: <Fallback />
  }
);
About.displayName = 'About';

const Screenshot = loadable(
  () => import(/* webpackChunkName: "Screenshot" */ './Screenshot/Screenshot'),
  {
    fallback: <Fallback />
  }
);
Screenshot.displayName = 'Screenshot';

const Demo = loadable(
  () => import(/* webpackChunkName: "Demo" */ './Demo/Demo'),
  {
    fallback: <Fallback />
  }
);
Demo.displayName = 'Demo';

const Contact = loadable(
  () => import(/* webpackChunkName: "Contact" */ './Contact/Contact'),
  {
    fallback: <Fallback />
  }
);
Contact.displayName = 'Contact';

const Footer = loadable(
  () => import(/* webpackChunkName: "Footer" */ './Footer/Footer'),
  {
    fallback: <Fallback />
  }
);
Footer.displayName = 'Footer';

const metaDescription =
  'An end to end event experience application that helps to host, manage, assist and organize multiple events and occasions from a centralised platform. It is a convenient and extremely helpful application for event management companies, event manager, organizations and also for common people.';

const Home = () => (
  <>
    <MetaComponent
      title="Sociana"
      description={metaDescription}
      url="https://sociana.com/"
    />
    <Navbar />
    <Landing />
    <Features />
    <Reviews />
    <About />
    <Screenshot />
    <Demo />
    <Contact />
    <Footer />
  </>
);

export default Home;
